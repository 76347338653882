// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info_formLabel__mC0dc {

    padding-bottom: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    text-transform: uppercase;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity, 1))
}

.user-info_formDataDisplay__L3Wvz {

    font-weight: 700
}

.user-info_formEdit__V7NuJ {

    cursor: pointer;

    --tw-text-opacity: 1;

    color: rgb(41 168 232 / var(--tw-text-opacity, 1))
}

.user-info_formControl__H0Qrs {

    width: 100%;

    border-width: 1px;

    background-color: transparent;

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity, 1));

    outline: 2px solid transparent;

    outline-offset: 2px
}
.user-info_formControl__H0Qrs.user-info_false__-T7I4 {

    pointer-events: none
}`, "",{"version":3,"sources":["webpack://./src/app/modules/user-info/index.module.scss"],"names":[],"mappings":"AACI;;IAAA,sBAAA;;IAAA,mBAAA;;IAAA,oBAAA;;IAAA,yBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA;AAAA;;AAIA;;IAAA,eAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,WAAA;;IAAA,iBAAA;;IAAA,6BAAA;;IAAA,oBAAA;;IAAA,qBAAA;;IAAA,oBAAA;;IAAA,uBAAA;;IAAA,oBAAA;;IAAA,mDAAA;;IAAA,8BAAA;;IAAA;AAAA;AAGI;;IAAA;AAAA","sourcesContent":[".formLabel {\n    @apply pb-2 text-gray-400 text-sm uppercase;\n}\n\n.formDataDisplay {\n    @apply font-bold;\n}\n\n.formEdit {\n    @apply text-jp-blue cursor-pointer;\n}\n\n.formControl {\n    @apply bg-transparent outline-none w-full border px-2 py-1 text-white;\n\n    &.false {\n        @apply pointer-events-none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLabel": `user-info_formLabel__mC0dc`,
	"formDataDisplay": `user-info_formDataDisplay__L3Wvz`,
	"formEdit": `user-info_formEdit__V7NuJ`,
	"formControl": `user-info_formControl__H0Qrs`,
	"false": `user-info_false__-T7I4`
};
export default ___CSS_LOADER_EXPORT___;
