import Skeleton from "react-loading-skeleton"
import paperPlane from "../../../assets/images/paper-plane.svg";
import { useState } from "react";
import { storeChatRequest } from "../../core/services/ai.service";
import { IChatMessage } from "../../core/interfaces/chat-message.interface";

interface Props {
    addNewChatMessage: (newChatMessage: IChatMessage) => void;
    hasChatMessages: boolean;
}

const AIForm: React.FC<Props> = ({ addNewChatMessage, hasChatMessages }) => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        storeChatRequest(message).then((res) => {
            addNewChatMessage(res.data);
            setMessage("");
            setIsLoading(false);
        });
    }

    return isLoading ?
        <div className="m-auto w-full px-2 xl:px-0 xl:w-2/3">
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
        </div>
        : <>
            {
                hasChatMessages ? <div className="relative m-auto w-full px-2 xl:px-0 xl:w-2/3">
                    <textarea className="outline-none bg-transparent m-auto w-full border-2 border-solid p-2 rounded-md border-[#EFEFEF]" rows={5} name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Talk with me more" />
                    <button className="absolute right-4 xl:right-2 bottom-4 bg-[#1691D0] p-2 rounded-md" onClick={handleSubmit}>
                        <img src={paperPlane} alt="Paper Plane" />
                    </button>
                </div> :
                    <>
                        <h2 className="text-2xl font-bold text-center">Lookin for answers? Just ask!</h2>
                        <div className="relative m-auto w-full px-2 xl:px-0 xl:w-2/3">
                            <textarea className="outline-none bg-transparent m-auto w-full border-2 border-solid p-2 rounded-md border-[#1691D0]" rows={10} name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Can you help me list down the best tips in achieving a successful life?" />
                            <button className="absolute right-4 xl:right-2 bottom-4 bg-[#1691D0] p-2 rounded-md" onClick={handleSubmit}>
                                <img src={paperPlane} alt="Paper Plane" />
                            </button>
                        </div>
                    </>
            }
        </>
}

export default AIForm;