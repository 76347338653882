// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-timer_grid-wrapper__yJDfF {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  text-align: center
}
.call-timer_grid-wrapper__yJDfF div:nth-child(3n) {
  padding-right: 2rem;
  text-align: right
}
.call-timer_grid-wrapper__yJDfF div:nth-child(3n-2) {
  padding-left: 2rem;
  text-align: left
}
.call-timer_grid-wrapper__yJDfF div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}
.call-timer_grid-wrapper__yJDfF div.call-timer_header-col-item__FWBjg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  background-color: #424242
}

.call-timer_icons__Zyybu {
  cursor: pointer;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.call-timer_icons__Zyybu:hover {
  --tw-text-opacity: 1;
  color: rgb(41 168 232 / var(--tw-text-opacity, 1))
}

.call-timer_grid-wrapper-stats__QvISH {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  text-align: center
}
.call-timer_grid-wrapper-stats__QvISH div:nth-child(2n) {
  padding-right: 2rem;
  text-align: right
}
.call-timer_grid-wrapper-stats__QvISH div:nth-child(2n-1) {
  padding-left: 2rem;
  text-align: left
}
.call-timer_grid-wrapper-stats__QvISH div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}
.call-timer_grid-wrapper-stats__QvISH div.call-timer_header-col-item__FWBjg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  background-color: #424242
}

.call-timer_icons__Zyybu {
  cursor: pointer;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.call-timer_icons__Zyybu:hover {
  --tw-text-opacity: 1;
  color: rgb(41 168 232 / var(--tw-text-opacity, 1))
}

.call-timer_activeSelection__LST3F {
  --tw-bg-opacity: 1;
  background-color: rgb(41 168 232 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(41 41 41 / var(--tw-text-opacity, 1))
}`, "",{"version":3,"sources":["webpack://./src/app/modules/call-timer/index.module.scss"],"names":[],"mappings":"AACI;EAAA,aAAA;EAAA,gDAAA;EAAA,mBAAA;EAAA;AAAA;AAGI;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA,mBAAA;EAAA;AAAA;AAGI;EAAA,iBAAA;EAAA,oBAAA;EAAA,kBAAA;EAAA,oBAAA;EACA;AADA;;AAOR;EAAA,eAAA;EAAA,wJAAA;EAAA,wDAAA;EAAA;AAAA;;AAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,aAAA;EAAA,gDAAA;EAAA,mBAAA;EAAA;AAAA;AAGI;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA,mBAAA;EAAA;AAAA;AAGI;EAAA,iBAAA;EAAA,oBAAA;EAAA,kBAAA;EAAA,oBAAA;EACA;AADA;;AAOR;EAAA,eAAA;EAAA,wJAAA;EAAA,wDAAA;EAAA;AAAA;;AAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,2DAAA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".grid-wrapper {\n    @apply grid grid-cols-3 text-center items-center;\n\n    div:nth-child(3n) {\n        @apply text-right pr-8;\n    }\n\n    div:nth-child(3n-2) {\n        @apply text-left pl-8;\n    }\n\n    div {\n        @apply py-2;\n\n        &.header-col-item {\n            @apply py-4 text-xl;\n            background-color: #424242;\n        }\n    }\n}\n\n.icons {\n    @apply hover:text-jp-blue transition cursor-pointer;\n}\n\n.grid-wrapper-stats {\n    @apply grid grid-cols-2 text-center items-center;\n\n    div:nth-child(2n) {\n        @apply text-right pr-8;\n    }\n\n    div:nth-child(2n-1) {\n        @apply text-left pl-8;\n    }\n\n    div {\n        @apply py-2;\n\n        &.header-col-item {\n            @apply py-4 text-xl;\n            background-color: #424242;\n        }\n    }\n}\n\n.icons {\n    @apply hover:text-jp-blue transition cursor-pointer;\n}\n\n.activeSelection {\n    @apply bg-jp-blue text-jp-black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-wrapper": `call-timer_grid-wrapper__yJDfF`,
	"header-col-item": `call-timer_header-col-item__FWBjg`,
	"icons": `call-timer_icons__Zyybu`,
	"grid-wrapper-stats": `call-timer_grid-wrapper-stats__QvISH`,
	"activeSelection": `call-timer_activeSelection__LST3F`
};
export default ___CSS_LOADER_EXPORT___;
