// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resources_listTitle__FbUSi {

    display: flex;

    cursor: pointer;

    align-items: center;

    gap: 0.5rem;

    text-transform: uppercase;

    letter-spacing: 0.025em;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity, 1))
}

.resources_listItem__Pjcxz {

    cursor: pointer;

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity, 1));

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.resources_listItem__Pjcxz:hover {

    --tw-text-opacity: 1;

    color: rgb(41 168 232 / var(--tw-text-opacity, 1))
}
.resources_listItem__Pjcxz.resources_active__60vnb {

    --tw-text-opacity: 1;

    color: rgb(41 168 232 / var(--tw-text-opacity, 1))
}`, "",{"version":3,"sources":["webpack://./src/app/modules/resources/index.module.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,eAAA;;IAAA,mBAAA;;IAAA,WAAA;;IAAA,yBAAA;;IAAA,uBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,eAAA;;IAAA,oBAAA;;IAAA,mDAAA;;IAAA,wJAAA;;IAAA,wDAAA;;IAAA;AAAA;;AAAA;;IAAA,oBAAA;;IAAA;AAAA;AAGI;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".listTitle {\n    @apply flex gap-2 items-center cursor-pointer text-gray-400 uppercase tracking-wide;\n}\n\n.listItem {\n    @apply text-gray-400 hover:text-jp-blue transition cursor-pointer;\n\n    &.active {\n        @apply text-jp-blue;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTitle": `resources_listTitle__FbUSi`,
	"listItem": `resources_listItem__Pjcxz`,
	"active": `resources_active__60vnb`
};
export default ___CSS_LOADER_EXPORT___;
