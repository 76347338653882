import { useState } from "react";
import videoIcon from "../../../assets/images/video.svg";
import audioIcon from "../../../assets/images/audio.svg";
import { IChatMessage } from "../../core/interfaces/chat-message.interface";
import AIForm from "./Form";
import { ITrack } from "../../core/interfaces/track.interface";
import PopupVideo from "./PopupVideo";

const AI: React.FC = () => {
    const [chatMessages, setChatMessages] = useState<IChatMessage[]>([]);
    const [selectedTrack, setSelectedTrack] = useState<ITrack>();

    const addNewChatMessage = (newChatMessage: IChatMessage) => {
        setChatMessages((prev) => [...prev, newChatMessage]);
    }

    const playMedia = (media: ITrack) => {
        setSelectedTrack(media);
    }

    const closeVideo = () => {
        setSelectedTrack(undefined);
    }

    return <div className="container text-white p-4 md:p-16">
        <div className="relative w-full">
            <div className="m-auto w-full px-2 xl:px-0 xl:w-2/3 mb-4 chat-response max-h-[600px] xl:max-h-[700px] overflow-y-auto pr-2 flex flex-col gap-y-5">
                {
                    chatMessages.map((chatMessage, i) => {
                        return <div className="flex flex-col gap-y-3" key={i}>
                            <div className="flex flex-col gap-2">
                                <div className="bg-[#535353] rounded-md p-3 w-3/4 ml-auto mr-0">{chatMessage.message}</div>
                                <div dangerouslySetInnerHTML={{ __html: chatMessage.response }}></div>
                                {
                                    chatMessage.tracks.length > 0 &&
                                    <>
                                        <hr />
                                        <div className="flex flex-col gap-2">
                                            <h2 className="text-3xl font-semibold">Browse Related Content</h2>
                                            {
                                                chatMessage.tracks.map((track) => {
                                                    return <div className="flex gap-x-2 cursor-pointer" onClick={() => playMedia(track)} key={track.id}>
                                                        <img src={track.image} alt={track.title} className="max-w-28 rounded-md" />
                                                        <div>
                                                            <img
                                                                src={track.type ===
                                                                    "video"
                                                                    ? videoIcon
                                                                    : audioIcon
                                                                }
                                                                alt="Album Type"
                                                            />
                                                            <h3 className="text-lg">{track.title}</h3>
                                                        </div>

                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    })
                }
            </div>
            <AIForm addNewChatMessage={addNewChatMessage} hasChatMessages={chatMessages.length > 0} />
            {
                selectedTrack && <PopupVideo track={selectedTrack} closeVideo={closeVideo} />
            }
        </div>
    </div>
};

export default AI;

