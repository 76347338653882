import { useRef } from "react";
import { ITrack } from "../../core/interfaces/track.interface";
import ReactJWPlayer from "react-jw-player";
import { v4 as uuidv4 } from "uuid";

interface Props {
    track: ITrack;
    closeVideo: () => void;
}

declare const window: any;

const PopupVideo: React.FC<Props> = ({ closeVideo, track }) => {
    const jwPlayerRef = useRef(null);

    const onClose = () => {
        window.jwplayer().stop();
        closeVideo();

    }

    return <div className="z-50 fixed top-0 left-0 w-screen h-screen bg-black">
        <div className="flex justify-center items-center h-full" onClick={onClose}>
            <div className="w-2/3 max-w-5xl" onClick={(e) => e.stopPropagation()}>
                <ReactJWPlayer
                    ref={jwPlayerRef}
                    playerId={uuidv4()}
                    playlist={
                        [
                            {
                                file: track.type === "video" ? `https://d3bjw5qhdr0t8c.cloudfront.net/${track.file_name}/high.m3u8?timestamp=${uuidv4()}` : `https://s3-ap-southeast-2.amazonaws.com/jpm-audio/high-${track.file_name}.mp3?timestamp=${uuidv4()}`,
                                image: track.image,
                                title: track.title,
                            }
                        ]
                    }
                    playerScript="https://cdn.jwplayer.com/libraries/7SGycuQW.js"
                    isAutoPlay={false}
                    image={track.image}
                />
            </div>
        </div>
    </div>

};

export default PopupVideo;
